.buttonFlexNav {
    display:flex;
    justify-content: space-between;
    margin-top: 20px;
}

.drawerButtonNav {
    border-bottom: solid 1px var(--positive);
    border-radius: 0px;
    padding: 5px 0px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.drawerButtonNav img {
    height: 50px;
    width: auto;
}

.drawerButtonNav:hover {
    cursor: pointer;
}


@media only screen and (max-width: 650px) {
    
    .drawerButtonNav {
        flex-direction: row;
        height:fit-content;
        justify-content: flex-start;
        align-items: center;
        margin-top: 15px;
    }
  
    .buttonFlexNav {
        flex-direction: column;
    }

    .drawerButtonNav img {
        height: 50px;
        width: auto;
        margin-right: 5px;
    }
}