
:root {
  --positive: #03B09B;
  --negative: #E55873;
  --midGreen: #9ADFD7;
  --midRed: #F8A1B4;
  --secondaryGreen: #F0FAF9;
  --lightRed: #FFF3F6;

  --backgroundLightGray: #F6F9FD;
  --borderGray: #D6DFEE;
  --lineGray: #DEDEDE;
  --waybozGray: #8594AC;

  --waybozBlue: #202B49;

  --cashFlow: #359BF2;
  --appreciation: #B45CD4;
  --principle: #F09E23;
  --principleLight: #FEF5E9;
 
  --reit:#A4D5FF;
  --largeCap:#EDBDFF;
  --bitcoin:#FFC0C0;
  --sp500:#96FAE8;
}

* {
  margin:0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.positive {
  color:var(--positive);
}

.midGreen {
  color:var(--midGreen);
}

.negative {
  color: var(--negative);
}

.primaryMetric {
  font-size: 45px;
  font-weight: bold;
}

.primaryMetricCalc {
  font-size: 45px;
  font-weight: bold;
}

.plaidExplain {
  font-size: 36px;
  font-weight: 500;
}

.headerMetric {
  font-size: 21px;
  font-weight: bold;
  display: inherit;
}

.headerMetricLight {
  font-size: 21px;
  font-weight: 400;
}

.headerLabel {
  font-size: 13px;
  font-weight: 500;
  color: var(--waybozGray);
}

.primaryLabel {
  color: var(--waybozBlue);
  font-size: 20px;
  font-weight: 600;
}

.primaryLabelLight {
  color: var(--waybozBlue);
  font-size: 20px;
  font-weight: 400;
}

.secondaryLabelWhite {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
}

.secondaryLabelBlue {
  color: var(--waybozBlue);
  font-size: 16px;
  font-weight: 700;
}

.secondaryLabelBlueSemi {
  color: var(--waybozBlue);
  font-size: 18px;
  font-weight: 600;
}

.secondaryLabelGreen {
  color: var(--positive);
  font-size: 16px;
  font-weight: 700;
}

.secondaryLabelGray {
  color: var(--waybozGray);
  font-size: 16px;
  font-weight: 500;
}

.secondaryLabelRed {
  color: var(--negative);
  font-size: 16px;
  font-weight: 500;
}

.tertiaryLabel {
    font-size: 14px;
    font-weight: 600;
}

.tertiaryLabelLightColorless {
  font-size: 14px;
  font-weight: 400;
}

.tertiaryLabelLight {
  font-size: 14px;
  font-weight: 400;
  color: var(--waybozGray);
}

.quadLabelLight {
  font-size: 13px;
  font-weight: 400;
  color: var(--waybozGray);
}

.bodyTextGray {
  color: var(--waybozGray);
  font-size: 14px;
  font-weight: 400;
}

.bodyTextGrayBold {
  color: var(--waybozGray);
  font-size: 14px;
  font-weight: 600;
}

.bodyTextBlueBold {
  color: var(--waybozBlue);
  font-size: 14px;
  font-weight: 600;
}

.bodyTextGreenBold {
  color: var(--positive);
  font-size: 14px;
  font-weight: 600;
}

.bodyTextBlue {
  color: var(--waybozBlue);
  font-size: 16px;
  font-weight: 400;
}



.bodyTextGreen {
  color: var(--positive);
  font-size: 16px;
  font-weight: 400;
}

.semiBold {
  font-weight: 600;
}

.veryBold {
  font-weight: 800;
}

.principle {
  color: var(--principle);
}

.cashFlow {
  color: var(--cashFlow);
}

.appreciation {
  color: var(--appreciation);
}

.pointer:hover {
  cursor: pointer;
}

.reit {
  color:var(--reit);
}

.largeCap {
  color:var(--largeCap);
}

.bitcoin {
  color:var(--bitcoin);
}

.sp500 {
  color:var(--sp500);
}

@media only screen and (max-width: 799px) {
  .primaryMetricCalc {
    font-size: 36px;
  }
}

@media only screen and (max-width: 599px) {
  .primaryMetricCalc {
    font-size: 28px;
  }
}

@media only screen and (max-width: 499px) {
  .primaryMetricCalc {
    font-size: 24px;
  }
}

@media only screen and (max-width: 399px) {
  .primaryMetricCalc {
    font-size: 20px;
  }
}