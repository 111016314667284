.dashboardRowTwoColumns {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.dashboardColHalf {
    overflow: auto;
    min-width: 40%;
    text-align: left;
    flex-basis: 575px;
    flex-grow: 1;
}

.dashboardDataContainer {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
}

.dashboardDataContainerHalf1 {
    overflow: auto;
    min-width: 60%;
    text-align: left;
    flex-basis: 275px;
    flex-grow: 1;
}

.dashboardDataContainerHalf2 {
    overflow: auto;
    min-width: 20%;
    flex-basis: 190px;
    flex-grow: 1;
    align-items: center;
}

.labelAndNum {
    display:flex;
    gap: 40px;
    align-items: center;
}

.circleAndLabel {
    display:flex;
    justify-content: start;
    align-items: center;
    padding: 10px 0px;
}

.labelCol {
    text-align: start;
}

.numCol{
    text-align: end;
}

.labelAndNum p {
    margin-left: 10px;
}

.numCol p {
    padding: 10px 0px;
}

.borderBottomLine {
    border-bottom: 1px solid var(--lineGray);
}

.needleChart{
    display:flex;
    justify-content: center;
    align-items: center;
    height: 127px
}

.valueChart {
    display:flex;
    justify-content: center;
    align-items: center;
    height: 150px 
}

.lineChartStyle {
    width: auto;                /* Only use as much width as needed */
    height: 125px;
    display: flex;
    justify-content: center;    /* Center align horizontally */
    align-items: center;        /* Center align vertically */
    margin: 0 auto;             /* Optional: Center the chart within its container */
}

.circle {
    width: 10px;           /* Set the width */
    height: 10px;          /* Set the height (same as width) */
    border-radius: 50%;
}




/*Tax Liability Items */
.taxFlex {
    display: flex;
    justify-content: space-between;
    width: 100%;
}




/*ROE Chart & Legend Items */
.legendRows{
    padding-bottom: 20px;
}

.legendFlexRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:5px;
    padding: 5px 0px;
}

.square {
    width: 10px;           /* Set the width */
    height: 10px;          /* Set the height (same as width) */
}

.legendMargin {
    margin: 0px 60px 20px 60px;
}










/* Media query to detect when the containers wrap and apply space-between */
@media (max-width: 560px) {  /* Adjust the max-width value based on your layout needs */
    .labelAndNum {
        gap: 0;  /* Remove the gap */
        justify-content: space-between;  /* Distribute items with space between */
    }
    .legendMargin {
        margin: 0px 20px 20px 20px;
    }
  }
  