.infoContainer {
    width: 100%;
    margin: 0px 0px 16px 0px;
}

.containerHeader {
    display: flex;
    background-color: var(--waybozBlue);
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 10px 10px 0px 0px;
    height: 20px;
}

.containerLabel {
    display:flex;
    justify-content: start;
    align-items: center;
}

.labelRightMargin {
    margin-right: 5px;
}

.transactionHeader {
    display: flex;
    background-color: var(--waybozBlue);
    padding: 10px;
    border-radius: 10px 10px 0px 0px;
    height: 20px;
    justify-content: space-between;
}

.containerBody {
    border: 1px solid var(--borderGray);
    border-radius: 0px 0px 10px 10px;
    background-color: #FFFFFF;
}

.calculatorBody {
    padding: 0px 30px 50px 30px;
}

@media only screen and (max-width: 799px) {
    .calculatorBody {
        padding: 0px 0px 50px 0px;
    }
}

.edit {
    display:flex;
    align-items: center;
}

.edit:hover {
    cursor: pointer;
}

.edit p {
    padding-right: 10px;
}

.paginationContainer {
    display:flex;
    justify-content: center;
    margin: 15px 0px;
}

.paginationSelected {
    background-color:var(--positive);
    color:white;
    font-weight: 700;
    border-radius: 50%;
}

.paginationItem {
    width: 40px;
    height: 40px;
    display:flex;
    align-items: center;
    justify-content: center;
}

.paginationItem:hover{
    cursor: pointer;
}

.transRowHover:hover{
    background-color: var(--secondaryGreen);
}

.summaryMetrics {
    display:flex;
}

.labelSummary {
    display:flex;
    align-items: center;
}

.labelSummaryPadding {
    margin-right: 5px;
}